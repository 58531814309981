<template>
  <div class="categorie-setting">
    <div class="header">
      <div class="titre">
        <h4>Gestion des Catégories</h4>
      </div>
      <div class="button">
        <b-dropdown text="Ajouter une catégorie" size="sm" variant="success">
          <b-dropdown-item
            v-for="item in [
              ...computedGetCustomFieldsMenu,
              // { id: 'file', name: 'Fichiers' },
              { id: 'pj', name: 'Pièce jointe' }
            ]"
            :key="item.id"
            v-b-modal.addCategorieModal
            :disabled="getCustomFieldsLoading"
            @click="Drapdownbutton(item)"
          >
            {{ item.nameMajuscule ? item.nameMajuscule : item.name }}
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </div>
    <div v-if="initLoading" class="init-loading three-dots-loading mt-2">
      Chargement en cours
    </div>
    <div v-else class="mt-2">
      <div
        v-if="getCategorieLoading || getProjectsProcessing"
        class="init-loading three-dots-loading"
      >
        Chargement en cours
      </div>
      <div v-if="getCategorieError" class="error">
        <ul v-if="Array.isArray(getCategorieError)" class="ul-error">
          <li v-for="(e, index) in getCategorieError" :key="index">
            {{ e }}
          </li>
        </ul>
        <span v-else>{{ getCategorieError }}</span>
      </div>
    </div>
    <div class="blockMenu">
      <div
        @click="clickMenu('contacts')"
        class="menu-item"
        :class="{ 'menu-item-active': selectedMenu === 'contacts' }"
      >
        Contacts
      </div>
      <div
        @click="clickMenu('projet')"
        class="menu-item"
        :class="{ 'menu-item-active': selectedMenu === 'projet' }"
      >
        Projects
      </div>
      <!-- <div
        @click="clickMenu('fichier')"
        class="menu-item"
        :class="{ 'menu-item-active': selectedMenu === 'fichier' }"
      >
        Fichiers
      </div> -->
      <div
        @click="clickMenu('PieceJointe')"
        class="menu-item"
        :class="{ 'menu-item-active': selectedMenu === 'PieceJointe' }"
      >
        Pièce jointe
      </div>
    </div>
    <hr class="lineMenu" />
    <div v-if="initLoading" class="init-loading three-dots-loading">
      Chargement en cours
    </div>

    <div v-else class="card-scene" ref="cardSceneTypeProject">
      <div v-if="selectedMenu === 'projet'">
        <Container
          :drop-placeholder="dropPlaceholderOptions"
          :lock-axis="'y'"
          @drop="onColumnDropProjects"
          drag-handle-selector=".column-drag-container"
        >
          <Draggable
            v-for="(categorie, index) in getCategoriesProjects"
            :key="index"
            :value="index.id"
          >
            <div class="column-drag-container">
              <div class="card-column-header">
                <div class="column-drag-handle move">
                  ☰
                </div>
                <div class="input-title-sous-type responsive">
                  <div class="input-content">
                    <input
                      class="input-focus"
                      placeholder="Nom de catégorie"
                      @change="updateNameCategorie($event, categorie)"
                      title="Modifier le nom de catégorie"
                      :value="categorie.name"
                    />
                  </div>
                  <div class="content-type-categorie">
                    <span class="mr-2">Types de projet :</span>
                    <multiselect
                      class="multiselectTC"
                      @input="updateTypeCategorie($event, categorie)"
                      v-model="categorie.dtypes"
                      :options="getProjectsTypes"
                      placeholder="Rechercher une type de projet"
                      :close-on-select="true"
                      :clear-on-select="true"
                      :preserve-search="true"
                      :showLabels="false"
                      label="name"
                      track-by="name"
                      :multiple="true"
                    >
                      <span slot="noResult">Aucune option trouvée.</span>
                      <span slot="noOptions">Aucune option trouvée.</span>
                    </multiselect>
                  </div>
                  <div class="action-drag">
                    <div>
                      <router-link
                        :to="
                          '/setting/categorie-projet/' +
                            categorie.id +
                            '/sous-categorie'
                        "
                        class="user-link"
                      >
                        <li>
                          Configuration des sous-catégories
                        </li>
                      </router-link>
                      <!-- <br /> -->
                      <router-link
                        :to="
                          '/setting/categorie-projet/' +
                            categorie.id +
                            '/pipeline'
                        "
                        class="user-link"
                      >
                        <li>
                          Configuration Pipeline
                        </li>
                      </router-link>
                    </div>
                  </div>
                  <div class="icon-sous-categorie responsive">
                    <b-button
                      size="sm"
                      class="btn-left non-drag-area"
                      @click="handleDeleteClick(categorie, (type = 'projects'))"
                      title="Supprimer une catégorie"
                    >
                      <font-awesome-icon class icon="times" />
                    </b-button>
                  </div>
                </div>
              </div>
            </div>
          </Draggable>
        </Container>
        <div
          class="style-aucun-category"
          v-if="
            (!getCategoriesProjects || !getCategoriesProjects.length) &&
              !getCategorieLoading &&
              !getProjectsProcessing
          "
        >
          Aucun catégorie de projet
        </div>
      </div>
      <div v-if="selectedMenu === 'contacts'">
        <Container
          :drop-placeholder="dropPlaceholderOptions"
          :lock-axis="'y'"
          @drop="onColumnDropContact"
          drag-handle-selector=".column-drag-container "
        >
          <Draggable
            v-for="(categorie, index) in getCategoriesContacts"
            :key="index"
            :value="index.id"
          >
            <div class="column-drag-container">
              <div class="card-column-header">
                <div class="column-drag-handle move">
                  ☰
                </div>
                <div class="input-title-sous-type responsive">
                  <div class="input-content">
                    <input
                      class="input-focus"
                      placeholder="Nom de catégorie"
                      @change="updateNameCategorie($event, categorie)"
                      title="Modifier le nom de catégorie"
                      :value="categorie.name"
                    />
                  </div>
                  <div class="content-type-categorie">
                    <span class="mr-2">Types de projet :</span>
                    <multiselect
                      class="multiselectTC"
                      @input="updateTypeCategorie($event, categorie)"
                      v-model="categorie.dtypes"
                      :options="getProjectsTypes"
                      placeholder="Rechercher une type de projet"
                      :close-on-select="true"
                      :clear-on-select="true"
                      :preserve-search="true"
                      :showLabels="false"
                      label="name"
                      track-by="name"
                      :multiple="true"
                    >
                      <span slot="noResult">Aucune option trouvée.</span>
                      <span slot="noOptions">Aucune option trouvée.</span>
                    </multiselect>
                  </div>
                  <div class="action-drag">
                    <router-link
                      :to="
                        '/setting/categorie-contact/' +
                          categorie.id +
                          '/sous-categorie'
                      "
                      class="user-link"
                    >
                      <li>
                        Configuration des sous-catégories
                      </li>
                    </router-link>
                  </div>
                  <div class="icon-sous-categorie responsive">
                    <b-button
                      size="sm"
                      class="btn-left non-drag-area"
                      @click="handleDeleteClick(categorie, (type = 'contacts'))"
                      title="Supprimer une catégorie"
                    >
                      <font-awesome-icon class icon="times" />
                    </b-button>
                  </div>
                </div>
              </div>
            </div>
          </Draggable>
        </Container>
        <div
          class="style-aucun-category"
          v-if="
            (!getCategoriesContacts || !getCategoriesContacts.length) &&
              !getCategorieLoading &&
              !getProjectsProcessing
          "
        >
          Aucun catégorie de contact
        </div>
      </div>
      <!-- <div v-if="selectedMenu === 'fichier'" class="dragable-file">
        <Container
          :drop-placeholder="dropPlaceholderOptions"
          :lock-axis="'y'"
          @drop="onColumnDropFichiers"
          drag-handle-selector=".column-drag-container-fichier"
        >
          <Draggable
            v-for="(categorie, index) in getProjectsCategoriesFichiers"
            :key="index"
            :value="index.id"
          >
            <div class="column-drag-container-fichier">
              <div class="card-column-header">
                <div class="column-drag-handle">
                  ☰
                </div>
                <div class="input-title-nom-categorie">
                  <div class="input-content">
                    {{ categorie.name }}
                  </div>

                  <div class="icon-sous-categorie">
                    <b-button
                      class="mr-2"
                      size="sm"
                      variant="primary"
                      title="Modifier"
                      @click.prevent.stop="handleUpdateClick(categorie)"
                    >
                      <font-awesome-icon class icon="pencil-alt" />
                    </b-button>

                    <b-button
                      size="sm"
                      title="Supprimer"
                      variant="danger"
                      @click.prevent.stop="
                        handleDeleteClick(categorie, (type = 'fichiers'))
                      "
                    >
                      <font-awesome-icon class icon="trash-alt" />
                    </b-button>
                  </div>
                </div>
              </div>
            </div>
          </Draggable>
        </Container>
        <div
          class="style-aucun-category"
          v-if="
            !getProjectsCategoriesFichiers ||
              !getProjectsCategoriesFichiers.length
          "
        >
          Aucun catégorie de fichier
        </div>
      </div> -->
      <div v-if="selectedMenu === 'PieceJointe'">
        <Container
          v-if="
            getCategoriesPieceJointeFile && getCategoriesPieceJointeFile.length
          "
          :drop-placeholder="dropPlaceholderOptions"
          :lock-axis="'y'"
          @drop="onColumnDropFilePJ"
          drag-handle-selector=".column-drag-container "
        >
          <!-- getCategoriesPieceJointeFile -->
          <Draggable
            v-for="(categorie, index) in getCategoriesPieceJointeFile"
            :key="index"
            :value="index.id"
          >
            <div class="column-drag-container">
              <div class="card-column-header">
                <div class="column-drag-handle move">
                  ☰
                </div>
                <div class="input-title-sous-type responsive">
                  <div class="input-content">
                    <input
                      class="input-focus"
                      placeholder="Nom de catégorie"
                      @change="updateNameCategoriePJ($event, categorie)"
                      title="Modifier le nom de catégorie"
                      :value="categorie.name"
                    />
                  </div>
                  <div class="content-type-categorie">
                    <span class="mr-2">Types d'équipe : </span>
                    <multiselect
                      class="multiselectTC"
                      @input="updateTypeCategoriePJ($event, categorie)"
                      v-model="categorie.types"
                      :options="getTableTeamsTypes"
                      placeholder="Rechercher une équipe"
                      :close-on-select="true"
                      :clear-on-select="true"
                      :preserve-search="true"
                      :showLabels="false"
                      label="designation"
                      track-by="id"
                      :multiple="true"
                    >
                      <span slot="noResult">Aucune option trouvée.</span>
                      <span slot="noOptions">Aucune option trouvée.</span>
                    </multiselect>
                  </div>
                  <div class="action-drag">
                    <router-link
                      :to="
                        '/setting/categorie-piece-jointe/' +
                          categorie.id +
                          '/sous-categorie'
                      "
                      class="user-link"
                    >
                      <li>
                        Configuration des sous-catégories
                      </li>
                    </router-link>
                  </div>
                  <div class="icon-sous-categorie responsive">
                    <b-button
                      size="sm"
                      class="btn-left non-drag-area"
                      @click="
                        handleDeleteClick(categorie, (type = 'PieceJointe'))
                      "
                      title="Supprimer une catégorie"
                    >
                      <font-awesome-icon class icon="times" />
                    </b-button>
                  </div>
                </div>
              </div>
            </div>
          </Draggable>
        </Container>
        <div
          class="style-aucun-category"
          v-if="
            (!getCategoriesPieceJointeFile ||
              !getCategoriesPieceJointeFile.length) &&
              !getCategorieLoading &&
              !getProjectsProcessing
          "
        >
          Aucun catégorie de pièce jointe
        </div>
      </div>
    </div>

    <!-- Add Categorie Modal -->
    <b-modal
      no-close-on-backdrop
      id="addCategorieModal"
      ref="addCategorieModal"
      :title="
        `Ajouter une catégorie ${
          selectedlinkButton && selectedlinkButton.id == 'pj'
            ? 'pièce jointe'
            : selectedlinkButton && selectedlinkButton.id == 42
            ? 'projet'
            : 'contact'
        }`
      "
      :hide-footer="true"
      @show="resetModal"
      @hidden="resetModal"
    >
      <form @submit.prevent="handleSubmit">
        <b-form-group label="Nom de catégorie" label-for="Nom-input">
          <b-form-input
            id="Nom-input"
            v-model="categorieToAdd.name"
            required
          ></b-form-input>
        </b-form-group>
        <template v-if="selectedlinkButton && selectedlinkButton.id != 'pj'">
          <b-form-group label="Types de projet" label-for="Nom-input">
            <multiselect
              v-model="categorieToAdd.dtypes"
              :options="getProjectsTypes"
              placeholder="Rechercher une type de projet"
              :close-on-select="true"
              :clear-on-select="true"
              :preserve-search="true"
              :showLabels="false"
              label="name"
              track-by="name"
              :multiple="true"
            >
              <span slot="noResult">Aucune option trouvée.</span>
              <span slot="noOptions">Aucune option trouvée.</span>
            </multiselect>
          </b-form-group>
        </template>
        <template v-if="selectedlinkButton && selectedlinkButton.id == 'pj'">
          <b-form-group label="Types d'équipe" label-for="Nom-input">
            <multiselect
              v-model="categorieToAdd.types"
              :options="getTableTeamsTypes"
              placeholder="Rechercher une équipe"
              :close-on-select="true"
              :clear-on-select="true"
              :preserve-search="true"
              :showLabels="false"
              label="designation"
              track-by="id"
              :multiple="true"
            >
              <span slot="noResult">Aucune option trouvée.</span>
              <span slot="noOptions">Aucune option trouvée.</span>
            </multiselect>
          </b-form-group>
        </template>
        <div class="message">
          <div v-if="getCategorieLoading" class="three-dots-loading loading">
            Chargement en cours
          </div>
          <div v-if="getCategorieError" class="error">
            <ul v-if="Array.isArray(getCategorieError)">
              <li v-for="(e, index) in getCategorieError" :key="index">
                {{ e }}
              </li>
            </ul>
            <span v-else>{{ getCategorieError }}</span>
          </div>
        </div>
        <div class="form-actions">
          <b-button
            @click="hideModal('addCategorieModal')"
            variant="outline-secondary"
          >
            Annuler
          </b-button>
          <b-button variant="success" type="submit">
            Enregistrer
          </b-button>
        </div>
      </form>
    </b-modal>
    <!-- END Add Categorie Modal -->
    <!-- update Categorie Modal -->
    <b-modal
      no-close-on-backdrop
      id="updateCategorieModal"
      ref="updateCategorieModal"
      title="Modifier une catégorie"
      :hide-footer="true"
      @show="resetModal"
      @hidden="resetModal"
    >
      <form v-if="categorieToUpdate" @submit.prevent="updateNameCategorieFiles">
        <b-form-group label="Nom de catégorie" label-for="Nom-input">
          <b-form-input
            id="Nom-input"
            v-model="categorieToUpdate.name"
            required
          ></b-form-input>
        </b-form-group>
        <div class="message">
          <div v-if="getCategorieLoading" class="three-dots-loading loading">
            Chargement en cours
          </div>
          <div v-if="getCategorieError" class="error">
            <ul v-if="Array.isArray(getCategorieError)">
              <li v-for="(e, index) in getCategorieError" :key="index">
                {{ e }}
              </li>
            </ul>
            <span v-else>{{ getCategorieError }}</span>
          </div>
        </div>
        <div class="form-actions">
          <b-button
            @click="hideModal('updateCategorieModal')"
            variant="outline-secondary"
          >
            Annuler
          </b-button>
          <b-button variant="success" type="submit">
            Enregistrer
          </b-button>
        </div>
      </form>
    </b-modal>
    <!-- END update Categorie Modal -->
    <!-- Delete Categorie Modal -->
    <b-modal
      no-close-on-backdrop
      ref="ModalCategorieDelete"
      id="ModalCategorieDelete"
      title="Supprimer une catégorie"
      @hidden="resetModal"
      :hide-footer="true"
    >
      <div
        v-if="
          selectedMenu === 'PieceJointe' &&
            categorieToDelete &&
            categorieToDelete.subCategorie &&
            categorieToDelete.subCategorie.length
        "
      >
        <p class="parg" v-if="!hiddenContent">
          Si vous voulez garder les sous catégories cliquer sur supprimer
          seulement catégorie sinon cliquer sur supprimer tout.
        </p>
        <p class="parg" v-if="hiddenContent">
          Veuillez sélectionner une catégorie dont la quelle vous voulez
          déplacer les sous catégories.
        </p>
        <div class="flex-btn">
          <b-button
            v-if="!hiddenContent"
            @click="afficherContentDeletedPJ"
            variant="outline-danger"
          >
            <font-awesome-icon class icon="trash-alt" /> Supprimer</b-button
          >
          <b-button
            v-if="!hiddenContent"
            @click="handleModalDelete(0)"
            variant="outline-danger"
            class="ml-3"
          >
            <font-awesome-icon class icon="trash-alt" /> Supprimer
            tout</b-button
          >
        </div>

        <div v-if="hiddenContent" class="select-pj">
          <multiselect
            class="multiselectTC"
            v-model="moveCategorie"
            :options="
              getCategoriesPieceJointeFile.filter(
                item => item.id != categorieToDelete.id
              ) || []
            "
            placeholder="Rechercher une catégorie"
            :close-on-select="true"
            :clear-on-select="true"
            :preserve-search="true"
            :showLabels="false"
            label="name"
            track-by="id"
            :multiple="false"
          >
            <span slot="noResult">Aucune option trouvée.</span>
            <span slot="noOptions">Aucune option trouvée.</span>
          </multiselect>
        </div>
      </div>
      <p class="parg" v-else>
        Êtes-vous certain de vouloir supprimer la catégorie :
        <strong> {{ categorieToDelete ? categorieToDelete.name : '' }}</strong>
        ?
      </p>

      <div class="message">
        <div v-if="getCategorieLoading" class="three-dots-loading loading">
          Chargement en cours
        </div>
        <div v-if="getCategorieError" class="error">
          <ul v-if="Array.isArray(getCategorieError)">
            <li v-for="(e, index) in getCategorieError" :key="index">
              {{ e }}
            </li>
          </ul>
          <span v-else>{{ getCategorieError }}</span>
        </div>
        <div v-if="error" class="error">
          <span>{{ error }}</span>
        </div>
      </div>
      <div class="form-actions mt-2">
        <b-button
          v-if="
            categorieToDelete &&
              (!categorieToDelete.subCategorie ||
                !categorieToDelete.subCategorie.length)
          "
          @click="hideModal('ModalCategorieDelete')"
          variant="outline-secondary"
          >Annuler</b-button
        >
        <b-button
          v-if="hiddenContent"
          @click="retourContentDeletedPJ"
          variant="outline-secondary"
          >Retour</b-button
        >
        <b-button
          variant="success"
          @click="handleModalDelete(moveCategorie)"
          v-if="
            selectedMenu === 'PieceJointe' &&
              categorieToDelete &&
              categorieToDelete.subCategorie &&
              categorieToDelete.subCategorie.length == 0
          "
          >Valider</b-button
        >
        <b-button
          variant="success"
          @click="handleModalDelete(moveCategorie)"
          v-if="
            selectedMenu === 'PieceJointe' &&
              categorieToDelete &&
              categorieToDelete.subCategorie &&
              categorieToDelete.subCategorie.length &&
              hiddenContent
          "
          >Valider</b-button
        >
        <b-button
          variant="success"
          @click="handleModalDelete"
          v-else-if="selectedMenu != 'PieceJointe'"
          >Valider</b-button
        >
      </div>
    </b-modal>
    <!-- END Delete Categorie Modal -->
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { Container, Draggable } from 'vue-smooth-dnd'
export default {
  data() {
    return {
      categorieToAdd: {
        name: null,
        dtypes: [],
        types: []
      },
      error: null,
      moveCategorie: null,
      categorieToUpdate: null,
      categorieToDelete: null,
      initLoading: true,
      customizable: 1,
      selectedlinkButton: { id: null, name: '' },
      dropPlaceholderOptions: {
        animationDuration: '150',
        showOnTop: true
      },
      selectedMenu: null,
      typeOfCategorieToDelte: null,
      id_contacts: null,
      id_projects: null,
      hiddenContent: false
    }
  },

  methods: {
    ...mapActions([
      'addCategoriesCategorie',
      'deleteCategoriesCategorie',
      'fetchAllTablesCustomFields',
      'resetErrorCategorie',
      'updateCategoriesCategorie',
      'updateOrderCategorieProject',
      'updateOrderCategorieContacts',
      'fetchCategoriesContacts',
      'fetchCategoriesProjects',
      'fetchProjetsTypes',
      'fetchCategoriesFichiers',
      'fetchCategorieFiles',
      'addCategoriesFiles',
      'deleteCategoriesFiles',
      'updateCategoriesFiles',
      'updateOrderCategorieFiles',
      'deleteCategoriesFilePJ',
      'updateOrderCategorieFilePJ',
      'fetchTypesEquipes',
      'fetchCategoriesPieceJointeFile',
      'updateCategoriesFilesPJ',
      'addCategoriesFilesPJ'
    ]),
    async clickMenu(menu) {
      this.selectedMenu = menu
      if (this.selectedMenu === 'contacts') {
        this.$router.replace('/setting/categorie-contact').catch(() => {})
        await this.fetchCategoriesContacts(this.id_contacts.id)
      }
      if (this.selectedMenu === 'projet') {
        this.$router.replace('/setting/categorie-projet').catch(() => {})
        await this.fetchCategoriesProjects(this.id_projects.id)
      }
      if (this.selectedMenu === 'fichier') {
        if (
          !this.getProjectsCategoriesFichiers ||
          !this.getProjectsCategoriesFichiers.length
        ) {
          await this.fetchCategorieFiles()
        }
      }
      if (this.selectedMenu === 'PieceJointe') {
        this.$router.replace('/setting/categorie-piece-jointe').catch(() => {})
        await this.fetchCategoriesPieceJointeFile({ loading: true })
      }
    },
    async Drapdownbutton(linkButton) {
      this.selectedlinkButton = linkButton
      if (
        this.selectedMenu &&
        this.selectedMenu != 'fichier' &&
        this.selectedlinkButton &&
        this.selectedlinkButton.id == 'file'
      ) {
        if (
          !this.getProjectsCategoriesFichiers ||
          !this.getProjectsCategoriesFichiers.length
        ) {
          await this.fetchCategorieFiles()
        }
      }
    },
    hideModal(ref) {
      this.$refs[ref].hide()
      this.resetModal()
    },
    resetModal() {
      this.categorieToAdd = {
        name: null,
        dtypes: [],
        types: []
      }
      this.error = null
      this.moveCategorie = null
      this.categorieToDelete = null
      this.resetErrorCategorie()
    },
    handleUpdateClick(category) {
      this.categorieToUpdate = { ...category }
      this.$refs['updateCategorieModal'].show()
    },
    async handleDeleteClick(categorieDelete, type) {
      this.categorieToDelete = { ...categorieDelete }
      this.typeOfCategorieToDelte = type
      this.$refs['ModalCategorieDelete'].show()
    },
    async updateNameCategorieFiles() {
      const response = await this.updateCategoriesFiles({
        categorie: this.categorieToUpdate,
        data: { name: this.categorieToUpdate.name }
      })
      if (response) {
        this.hideModal('updateCategorieModal')
      }
    },
    async handleSubmit() {
      // if (this.selectedlinkButton && this.selectedlinkButton.id != 'file') {
      if (this.selectedlinkButton && this.selectedlinkButton.id != 'pj') {
        const response = await this.addCategoriesCategorie({
          categorie: this.categorieToAdd,
          table_id: this.selectedlinkButton
        })
        if (response) {
          this.hideModal('addCategorieModal')
        }
      } else {
        // let count = this.getProjectsCategoriesFichiers.length
        // const categorie = {
        //   name: this.categorieToAdd.name,
        //   order: count
        // }
        // const response = await this.addCategoriesFiles({
        //   categoriesFichiers: this.getProjectsCategoriesFichiers,
        //   categorie: categorie
        // })
        const categorie = {
          name: this.categorieToAdd.name,
          types:
            this.categorieToAdd.types && this.categorieToAdd.types.length
              ? this.categorieToAdd.types.map(item => item.id)
              : []
        }
        const response = await this.addCategoriesFilesPJ({
          categorie: categorie
        })
        if (response) {
          this.hideModal('addCategorieModal')
        }
      }
    },
    afficherContentDeletedPJ() {
      this.hiddenContent = true
    },
    retourContentDeletedPJ() {
      this.hiddenContent = false
      this.error = null
    },
    async handleModalDelete(deleteDefinitive) {
      this.error = null
      // if (this.typeOfCategorieToDelte != 'fichiers') {
      if (this.typeOfCategorieToDelte != 'PieceJointe') {
        const response = await this.deleteCategoriesCategorie({
          categorieToDelete: this.categorieToDelete,
          type: this.typeOfCategorieToDelte
        })
        if (response) {
          this.hideModal('ModalCategorieDelete')
        }
      } else {
        // const response = await this.deleteCategoriesFiles({
        //   categorieToDelete: this.categorieToDelete
        // })
        let value = null
        if (deleteDefinitive == 0 || deleteDefinitive != null) {
          value = deleteDefinitive
        } else if (
          deleteDefinitive == null &&
          this.categorieToDelete &&
          this.categorieToDelete.subCategorie &&
          this.categorieToDelete.subCategorie.length
        ) {
          this.error = 'Vous devez sélectionner une catégorie.'
        } else {
          value = 0
        }
        if (!this.error) {
          const response = await this.deleteCategoriesFilePJ({
            categorieToDelete: this.categorieToDelete,
            categorieToDeleteDefintive: value == 0 ? value : value.id
          })
          if (response) {
            this.hideModal('ModalCategorieDelete')
          }
        }
      }
    },
    onColumnDropContact(dropResult) {
      const { removedIndex, addedIndex } = dropResult
      if (removedIndex === addedIndex) {
        return
      }
      let categorieOrder = this.applyDrag(
        this.getCategoriesContacts,
        dropResult
      )
      this.updateOrderCategorieContacts(categorieOrder)

      this.updateCategoriesCategorie({
        categorie: this.getCategoriesContacts[addedIndex],
        data: {
          order: addedIndex
        },
        type: 'contacts'
      })
    },
    onColumnDropProjects(dropResult) {
      const { removedIndex, addedIndex } = dropResult
      if (removedIndex === addedIndex) {
        return
      }
      let categorieOrder = this.applyDrag(
        this.getCategoriesProjects,
        dropResult
      )
      this.updateOrderCategorieProject(categorieOrder)
      this.updateCategoriesCategorie({
        categorie: this.getCategoriesProjects[addedIndex],
        data: {
          order: addedIndex
        },
        type: 'projects'
      })
    },
    onColumnDropFilePJ(dropResult) {
      const { removedIndex, addedIndex } = dropResult
      if (removedIndex === addedIndex) {
        return
      }
      let categorieOrder = this.applyDrag(
        this.getCategoriesPieceJointeFile,
        dropResult
      )
      this.updateOrderCategorieFilePJ(categorieOrder)
      this.updateCategoriesFilesPJ({
        categorie: this.getCategoriesPieceJointeFile[addedIndex],
        data: {
          order: addedIndex
        }
      })
    },
    onColumnDropFichiers(dropResult) {
      const { removedIndex, addedIndex } = dropResult
      if (removedIndex === addedIndex) {
        return
      }
      let categorieOrder = this.applyDrag(
        this.getProjectsCategoriesFichiers,
        dropResult
      )
      this.updateOrderCategorieFiles(categorieOrder)
      this.updateCategoriesFiles({
        categorie: this.getProjectsCategoriesFichiers[addedIndex],
        data: {
          order: addedIndex
        }
      })
    },
    applyDrag(arr, dragResult) {
      const { removedIndex, addedIndex, payload } = dragResult
      if (removedIndex === null && addedIndex === null) return arr
      const result = [...arr]
      let itemToAdd = payload
      if (removedIndex !== null) {
        itemToAdd = result.splice(removedIndex, 1)[0]
      }
      if (addedIndex !== null) {
        result.splice(addedIndex, 0, itemToAdd)
      }
      for (let index = 0; index < result.length; index++) {
        result[index].order = index
      }
      return result
    },
    updateNameCategorie(e, categorie) {
      this.updateCategoriesCategorie({
        categorie: categorie,
        data: { name: e.target.value }
      })
    },
    updateNameCategoriePJ(e, categorie) {
      this.updateCategoriesFilesPJ({
        categorie: categorie,
        data: { name: e.target.value }
      })
    },
    updateTypeCategorie(e, categorie) {
      this.updateCategoriesCategorie({
        categorie: categorie,
        data: {
          dtypes: categorie.dtypes.map(item => {
            return item.id
          })
        }
      })
    },
    updateTypeCategoriePJ(e, categorie) {
      this.updateCategoriesFilesPJ({
        categorie: categorie,
        data: {
          types: categorie.types.map(item => {
            return item.id
          })
        }
      })
    },
    strUcFirst(a) {
      return (a + '').charAt(0).toUpperCase() + a.substr(1)
    }
  },
  components: {
    Container,
    Draggable
  },
  computed: {
    ...mapGetters([
      'getCategoriesCategorie',
      'getCategorieLoading',
      'getCategorieError',
      'getCustomFieldsMenu',
      'getCustomFieldsLoading',
      'getCategoriesContacts',
      'getCategoriesProjects',
      'getProjectsTypes',
      'getProjectsCategoriesFichiers',
      'getProjectsProcessing',
      'getTableTeamsTypes',
      'getCategoriesPieceJointeFile'
    ]),
    computedGetCustomFieldsMenu: function() {
      return this.getCustomFieldsMenu.map(item => {
        return { ...item, nameMajuscule: this.strUcFirst(item.name) }
      })
    }
  },
  watch: {
    async $route() {
      if (this.$route && this.$route.name == 'categorieContact') {
        this.selectedMenu = 'contacts'
        await this.fetchCategoriesContacts(this.id_contacts.id)
      }
    }
  },
  async mounted() {
    await this.fetchAllTablesCustomFields(this.customizable)
    this.fetchTypesEquipes()
    this.id_contacts = this.getCustomFieldsMenu.find(
      item => item.name === 'contacts'
    )
    this.id_projects = this.getCustomFieldsMenu.find(
      item => item.name === 'projets'
    )
    this.selectedlinkButton =
      this.id_contacts && this.id_contacts.id
        ? this.id_contacts
        : { id: 3, name: 'contacts' }
    await this.fetchProjetsTypes({
      exclude: ['fields']
    })
    if (this.$route && this.$route.name == 'categorieContact') {
      this.selectedMenu = 'contacts'
      await this.fetchCategoriesContacts(this.id_contacts.id)
    }
    if (this.$route && this.$route.name == 'categorieProjet') {
      this.selectedMenu = 'projet'
      await this.fetchCategoriesProjects(this.id_projects.id)
    }
    if (this.$route && this.$route.name == 'categoriePieceJointe') {
      this.selectedMenu = 'PieceJointe'
      await this.fetchCategoriesPieceJointeFile({ loading: true })
    }
    this.initLoading = false
  }
}
</script>
<style lang="scss" scoped>
.categorie-setting {
  .header {
    display: flex;
    // align-items: center;
    justify-content: space-between;
    width: 100%;
    .error {
      color: #e73e01;
      .ul-error {
        margin-top: 0px;
        margin-bottom: 0px;
      }
    }
  }
  .list-group {
    .list-group-item {
      p {
        margin-top: 0;
        margin-bottom: -1rem;
      }
      .content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .nom-type {
          font-weight: bold;
          text-transform: capitalize;
        }
        .actions {
          display: flex;
        }
        .fontright {
          margin-left: 16px;
        }
      }
      .mt-2 {
        ul {
          margin-top: 5px;
        }
      }

      &.collapsed {
        .icon {
          flex-direction: column !important;
        }
      }
    }
  }
  @media screen and (max-width: 580px) {
    .header {
      display: flex;
    }
    .input-title-sous-type {
      &.responsive {
        display: block;
        .input-content {
          input {
            width: 100%;
          }
        }
        .content-type-categorie {
          display: flex;
          // align-items: center;
          // justify-content: space-between;
          margin-top: 5px;
          .multiselect {
            width: 100%;
            margin-left: 1px;
          }
        }
        .action-drag {
          margin-top: 5px;
          margin-bottom: 6px;
        }
      }
    }
    .smooth-dnd-container {
      min-width: 107%;
      margin-left: -14px;
    }
    .icon-sous-categorie {
      &.responsive {
        width: 30px;
        border: 1px solid;
        margin: auto;
        border-radius: 20px;
      }
    }
  }
  @media screen and (max-width: 400px) {
    .header {
      display: block;
    }
    .input-title-sous-type {
      &.responsive {
        display: block;
        .input-content {
          input {
            width: 100%;
          }
        }
        .content-type-categorie {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 5px;
          .multiselect {
            width: 126px;
            margin-left: 1px;
          }
        }
        .action-drag {
          margin-top: 5px;
          margin-bottom: 6px;
        }
      }
    }
  }
  @media screen and (max-width: 280px) {
    .header {
      display: block;
    }
    .smooth-dnd-container {
      min-width: 114%;
      margin-left: -14px;
    }
    .input-title-sous-type {
      display: block;
      .content-type-categorie {
        display: block;
        margin-top: 5px;
        .multiselect {
          width: 126px;
          margin-left: -22px;
        }
      }
    }
    .blockMenu {
      display: block;
    }
    .icon-sous-categorie {
      &.responsive {
        color: rgba(73, 80, 87, 0.6705882353);
        background-color: transparent;
        border-color: transparent;
        -webkit-box-shadow: none;
        box-shadow: none;
        margin-left: 44px;
        border: 1px solid;
        padding-top: 8px;
        width: 20%;
        /* margin-right: 53px; */
        height: 34px;
        border-radius: 21px;
        button {
          svg {
            position: absolute;
            bottom: 18px;
            left: 89px;
          }
        }
      }
    }
  }
}
</style>
<style lang="scss">
#ModalCategorieDelete {
  strong {
    text-transform: capitalize;
  }
  .parg {
    margin-top: 0;
    margin-left: 10px;
    margin-bottom: 1rem;
  }
  .flex-btn {
    text-align: center;
    margin: 20px;
  }
  .select-pj {
    margin-bottom: 20px;
  }
  .trash {
    margin: 11px;
    color: red;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
  .message {
    .error {
      padding: 25px;
      color: #e73e01;
    }
  }
  p {
    margin-top: 0;
    margin-left: 10px;
    margin-bottom: 1rem;
  }
}

.categorie-setting {
  .dragable-file {
    .smooth-dnd-container.vertical > .smooth-dnd-draggable-wrapper {
      position: relative;
      overflow: visible !important;
      margin-top: 5px;
      border: 1px solid #6c757d52;
      padding: 4px;
      background-color: white;
      width: 100%;
      border-radius: 4px;
    }
  }
  .column-drag-handle,
  .ss-column-drag-handle {
    position: absolute;
    top: 10px;
    left: 6px;
    height: 25px;
    width: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    &.move {
      cursor: move;
    }
  }
  .card-column-header {
    position: relative;
    width: 100%;
    padding: 9px;
  }
  .card-scene {
    height: calc(100% - 180px);
    .style-aucun-category {
      padding: 15px;
      border: 1px solid #bfcdd2;
      text-align: center;
      color: #2dabe7;
    }
  }
  .input-title-sous-type {
    display: flex;
    justify-content: space-between;
    margin-left: 25px;
    .input-content {
      .input-focus {
        &:focus {
          background-color: #f8f9fa;
        }
      }
      input {
        color: black;
        overflow: visible;
        background-color: transparent;
        border: 0px;
        font-size: 13px;
        text-overflow: ellipsis;
      }
      .custom-select-sm {
        width: 100px;
      }
    }
    .content-type-categorie {
      display: flex;
      span {
        font-size: 13px;
        margin-right: 7px;
        margin-top: 4px;
      }
    }
  }
  .smooth-dnd-container.horizontal {
    display: flex;
  }
  .smooth-dnd-container.vertical > .smooth-dnd-draggable-wrapper {
    position: relative;
    overflow: visible !important;
    margin-top: 5px;
    border: 1px solid #6c757d52;
    padding: 4px;
    background-color: #b0e6f252;
    width: 100%;
    border-radius: 4px;
  }
  .smooth-dnd-container {
    position: relative;
    min-height: 30px;
    min-width: 255px;
  }
  .smooth-dnd-container.horizontal > .smooth-dnd-draggable-wrapper {
    border: 1px solid #6c757d4d;
    margin-right: 8px;
    background-color: #ebebeb5c;
    border-radius: 4px;
  }
  .action-drag {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .user-link {
      // margin-right: 3px;
      // margin-top: 4px;
      &:hover {
        color: #007bff;
      }
    }
  }
  .icon-sous-categorie {
    .btn-left {
      color: #495057ab;
      background-color: transparent;
      border-color: transparent;
      box-shadow: none;
      margin-right: 3px;
    }
    .btn-secondary:focus,
    .btn-secondary.focus {
      color: #495057ab;
      background-color: rgba(255, 255, 255, 0.075);
      border-color: rgba(255, 255, 255, 0.075);
    }
  }
  .blockMenu {
    display: flex;
    margin-top: 15px;
    .menu-item {
      cursor: pointer;
      text-decoration: none;
      color: #26292c;
      padding: 10px;
    }
    .menu-item-active {
      color: #2dabe2;
      border-bottom: 2px solid #2dabe2;
    }
  }
  .lineMenu {
    margin-top: -1px;
    margin-bottom: 1rem;
  }
  .multiselectTC {
    width: 250px;
    min-height: 28px;
    .multiselect__select {
      height: 31px;
      padding: 4px 8px;
    }
    .multiselect__tags {
      min-height: 26px;
      padding: 5px 40px 0 8px;
    }
    .multiselect__tag {
      padding: 3px 25px 3px 10px;
      margin-right: 10px;
      margin-bottom: 0px;
      .multiselect__tag-icon {
        top: -2px;
      }
    }
    .multiselect__placeholder {
      margin-bottom: 3px;
      padding-top: 0px;
    }
  }
}
</style>
